import React, { useState } from "react";
import { FaUser, FaWallet, FaRupeeSign, FaHome, FaCheck } from "react-icons/fa";
import axios from "axios";
import "./CreditDebitFund.css";

const CreditDebitFund = () => {
  const [method, setMethod] = useState("credit");
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [referralId, setReferralId] = useState("");
  const [referralUserName, setReferralUserName] = useState("");
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [message, setMessage] = useState("");

  const percentages = [2, 1, 0.4, 0.2, 0.1, 0]; // 5 cycles of commission percentages
  const ADMIN_REFERRAL_ID = "swamy520741808"; // Specify the ADMIN referral ID here

  const handleUserIdChange = async (e) => {
    const inputUserId = e.target.value;
    setUserId(inputUserId);

    if (inputUserId) {
      try {
        const response = await axios.get(
          ` https://backend.shnsm1.com/api/users/${inputUserId}`
        );
        if (response.status === 200 && response.data) {
          setUserName(response.data.userName);
          setReferralId(response.data.referralId);

          if (response.data.referralId) {
            const referralResponse = await axios.get(
              `https://backend.shnsm1.com/api/users/${response.data.referralId}`
            );
            if (referralResponse.status === 200 && referralResponse.data) {
              setReferralUserName(referralResponse.data.userName);
            } else {
              setReferralUserName("Referral user not found");
            }
          } else {
            setReferralUserName("");
          }
        } else {
          setUserName("User not found");
          setReferralId("");
          setReferralUserName("");
        }
      } catch (error) {
        console.error("Error fetching user:", error);
        setUserName("Error fetching user");
        setReferralId("");
        setReferralUserName("");
      }
    } else {
      setUserName("");
      setReferralId("");
      setReferralUserName("");
    }
  };

  const distributeCommission = async (
    currentSponsorId,
    level,
    originalAmount
  ) => {
    if (level >= percentages.length || !currentSponsorId) {
      console.log(
        `Ending distribution at level ${level + 1}: No valid sponsor.`
      );
      return;
    }

    try {
      console.log(
        `Level ${
          level + 1
        }: Fetching sponsor details for ID: ${currentSponsorId}`
      );
      const sponsorResponse = await axios.get(
        ` https://backend.shnsm1.com/api/users/${currentSponsorId}`
      );
      const sponsorData = sponsorResponse.data;

      if (!sponsorData) {
        console.error(
          `Sponsor not found at level ${level + 1}: ID ${currentSponsorId}`
        );
        return;
      }

      const blockedStatus = sponsorData.blocked;
      if (!blockedStatus) {
        const commissionAmount = (percentages[level] * originalAmount) / 100;
        try {
          const response = await axios.post(
            "https://backend.shnsm1.com/api/wallet/transactions",
            {
              userId: currentSponsorId,
              amount: commissionAmount,
              method: "credit",
              description: `Level ${
                level + 1
              } commission from User ID: ${userId}`,
            }
          );

          if (response.status !== 200) {
            console.error(`Failed to credit commission at level ${level + 1}.`);
            return;
          }
        } catch (error) {
          console.log("ERROR>>>>>>" + error);
          console.error(
            `Error processing commission at level ${level + 1}:`,
            error
          );
          return;
        }
      } else {
        console.log(
          `Skipping blocked sponsor at level ${
            level + 1
          }: ID ${currentSponsorId}`
        );
      }

      // Recursive call for next sponsor
      const nextSponsorId = sponsorData.referralId;
      if (!nextSponsorId) {
        console.log(
          `No next sponsor at level ${
            level + 1
          }, stopping commission distribution.`
        );
        return;
      }

      setTimeout(
        () => distributeCommission(nextSponsorId, level + 1, originalAmount),
        2000 // 1-second delay
      );
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error(
          `Sponsor not found at level ${level + 1}: ID ${currentSponsorId}`
        );
      } else {
        console.error(
          `Error fetching sponsor data at level ${level + 1}:`,
          error
        );
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!userId || !amount || !description) {
      setMessage("Please fill in all fields.");
      return;
    }

    if (parseFloat(amount) <= 0) {
      setMessage("Amount must be greater than 0.");
      return;
    }

    const transactionDate = new Date().toISOString();

    try {
      const transactionResponse = await axios.post(
        " https://backend.shnsm1.com/api/fund-transfers",
        {
          userId,
          amount: parseFloat(amount),
          description,
          method,
          transactionDate,
        }
      );

      if (
        transactionResponse.status === 200 ||
        transactionResponse.data.message === "Fund transfer saved successfully!"
      ) {
        setMessage("Transaction successful!");

        if (referralId === ADMIN_REFERRAL_ID) {
          setMessage(
            "Transaction successful! No commission distribution for ADMIN referral ID."
          );
        } else {
          setMessage(
            "Transaction successful! Starting commission distribution..."
          );
          await distributeCommission(referralId, 0, parseFloat(amount));
          setMessage("Commission distributed successfully!");
        }
      } else {
        setMessage("Transaction failed.");
      }
    } catch (error) {
      console.error("Error processing transaction:", error);
      setMessage("Error processing transaction.");
    }

    setUserId("");
    setUserName("");
    setReferralId("");
    setReferralUserName("");
    setAmount("");
    setDescription("");
  };

  return (
    <div className="form-container">
      <h2>Credit Fund</h2>
      <p>
        <FaHome /> / Wallet
      </p>

      <form className="form" onSubmit={handleSubmit}>
        <div className="form-group">
          <FaUser className="icon" /> &nbsp;
          <label htmlFor="userID">&nbsp; &nbsp; User ID : &nbsp; </label>
          <div className="input-group">
            <input
              type="text"
              id="userID"
              value={userId}
              onChange={handleUserIdChange}
              placeholder="Enter User ID"
            />
          </div>
          {userName && (
            <p className="user-name-display">Username: {userName}</p>
          )}
          {referralId && (
            <p className="referral-id-display">Referral ID: {referralId}</p>
          )}
          {!userId && referralUserName && (
            <p className="referral-user-name-display">
              Referral Username: {referralUserName}
            </p>
          )}
        </div>

        <div className="form-group">
          <FaWallet className="icon" />
          <label htmlFor="wallet">&nbsp; &nbsp; To Wallet : &nbsp;</label>
          <div className="input-group">
            <select id="wallet" disabled>
              <option value="e-wallet">E Wallet</option>
            </select>
          </div>
        </div>

        <div className="form-group">
          <label>Select Method &nbsp; &nbsp;</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                value="credit"
                checked={method === "credit"}
                onChange={() => setMethod("credit")}
              />
              Credit
            </label>
          </div>
        </div>

        <div className="form-group">
          <FaRupeeSign className="icon" />
          <label htmlFor="amount"> &nbsp; &nbsp; Amount : &nbsp;</label>
          <div className="input-group">
            <input
              type="number"
              id="amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Enter Amount"
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="description">Description : &nbsp;&nbsp;&nbsp;</label>
          <textarea
            id="description"
            rows="4"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter Description"
          ></textarea>
        </div>

        {message && <p className="message">{message}</p>}

        <div className="form-buttons">
          <br />
          <button type="submit" className="btn btn-primary">
            <FaCheck className="icon" /> Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreditDebitFund;
